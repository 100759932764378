import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { cards } from '../data/cards';
import { solutions } from '../data/solutions';
import { useStore } from '../store/useStore';

export const SolutionsPage: React.FC = () => {
  const { cardId } = useParams();
  const navigate = useNavigate();
  const { cardSelections, addCardSelection, updateCardSelection } = useStore();
  
  const currentCard = cards.find(card => card.id === Number(cardId));
  const currentSelection = cardSelections.find(s => s.cardId === Number(cardId));
  
  const [selectedSolutions, setSelectedSolutions] = React.useState<number[]>(
    currentSelection?.solutions || []
  );

  const handleSolutionToggle = (solutionId: number) => {
    setSelectedSolutions(prev => 
      prev.includes(solutionId)
        ? prev.filter(id => id !== solutionId)
        : [...prev, solutionId]
    );
  };

  const handleContinue = () => {
    if (selectedSolutions.length > 0) {
      const selection = {
        cardId: Number(cardId),
        solutions: selectedSolutions,
        helpSources: currentSelection?.helpSources || []
      };
      
      if (currentSelection) {
        updateCardSelection(selection);
      } else {
        addCardSelection(selection);
      }
      
      navigate(`/help-sources/${cardId}`);
    }
  };

  if (!currentCard) {
    return <div>Kaart niet gevonden</div>;
  }

  return (
    <div className="min-h-screen bg-white py-8">
      <div className="container mx-auto px-4">
        <div className="max-w-7xl mx-auto">
          <header className="mb-8">
            <h1 className="text-4xl font-bold text-summa-red mb-2">
              Kies Oplossingen
            </h1>
            <p className="text-gray-600">
              Voor: {currentCard.text}
            </p>
          </header>

          <div className="grid gap-4 mb-24">
            {solutions.map((solution) => (
              <button
                key={solution.id}
                onClick={() => handleSolutionToggle(solution.id)}
                className={clsx(
                  'p-4 rounded-lg text-left transition-all',
                  'border-2 hover:border-summa-red',
                  selectedSolutions.includes(solution.id)
                    ? 'border-summa-red bg-summa-red/5'
                    : 'border-gray-200'
                )}
              >
                <p className="font-medium">{solution.text}</p>
              </button>
            ))}
          </div>

          <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-4">
            <div className="max-w-7xl mx-auto flex items-center justify-between">
              <button
                onClick={() => navigate('/selected-cards')}
                className="text-gray-600 hover:text-gray-900"
              >
                Terug naar overzicht
              </button>
              <button
                onClick={handleContinue}
                disabled={selectedSolutions.length === 0}
                className={clsx(
                  'px-6 py-3 rounded-lg font-medium transition-all',
                  'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-summa-red',
                  selectedSolutions.length === 0
                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    : 'bg-summa-red text-white hover:bg-summa-red-dark shadow-md hover:shadow-lg'
                )}
              >
                Ga naar hulpbronnen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};