import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CardSelectionPage } from './pages/CardSelectionPage';
import { SelectedCardsPage } from './pages/SelectedCardsPage';
import { SolutionsPage } from './pages/SolutionsPage';
import { HelpSourcesPage } from './pages/HelpSourcesPage';
import { ExportPage } from './pages/ExportPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<CardSelectionPage />} />
        <Route path="/selected-cards" element={<SelectedCardsPage />} />
        <Route path="/solutions/:cardId" element={<SolutionsPage />} />
        <Route path="/help-sources/:cardId" element={<HelpSourcesPage />} />
        <Route path="/export" element={<ExportPage />} />
      </Routes>
    </Router>
  );
}

export default App;