export const cards = Array.from({ length: 60 }, (_, index) => ({
  id: index + 1,
  text: `Ik heb moeite met ${[
    'het plannen van mijn huiswerk',
    'concentratie tijdens de les',
    'het begrijpen van wiskunde',
    'het leren voor toetsen',
    'het maken van samenvattingen',
    'het stellen van vragen in de klas',
    'het werken in groepjes',
    'het bijhouden van deadlines',
    'het maken van presentaties',
    'het begrijpen van teksten',
  ][index % 10]}`
}));