export const helpSources = [
  {
    id: 1,
    title: "Studiecoach",
    description: "Een-op-een begeleiding bij het verbeteren van je studiemethoden"
  },
  {
    id: 2,
    title: "Huiswerkbegeleiding",
    description: "Extra ondersteuning na schooltijd bij het maken van huiswerk"
  },
  {
    id: 3,
    title: "Online Leerplatform",
    description: "Toegang tot extra oefenmateriaal en uitlegvideo's"
  },
  {
    id: 4,
    title: "Mentorgesprek",
    description: "Persoonlijk gesprek met je mentor over je voortgang"
  },
  {
    id: 5,
    title: "Bijles",
    description: "Extra uitleg van een vakdocent of ouderejaars student"
  },
  {
    id: 6,
    title: "Studiegroepen",
    description: "Samen studeren met klasgenoten onder begeleiding"
  }
];