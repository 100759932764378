import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { cards } from '../data/cards';
import { helpSources } from '../data/helpSources';
import { useStore } from '../store/useStore';

export const HelpSourcesPage: React.FC = () => {
  const { cardId } = useParams();
  const navigate = useNavigate();
  const { cardSelections, updateCardSelection } = useStore();
  
  const currentCard = cards.find(card => card.id === Number(cardId));
  const currentSelection = cardSelections.find(s => s.cardId === Number(cardId));
  
  const [selectedHelpSources, setSelectedHelpSources] = React.useState<number[]>(
    currentSelection?.helpSources || []
  );

  const handleHelpSourceToggle = (sourceId: number) => {
    setSelectedHelpSources(prev => 
      prev.includes(sourceId)
        ? prev.filter(id => id !== sourceId)
        : [...prev, sourceId]
    );
  };

  const handleSave = () => {
    if (selectedHelpSources.length > 0 && currentSelection) {
      updateCardSelection({
        ...currentSelection,
        helpSources: selectedHelpSources
      });
      navigate('/selected-cards');
    }
  };

  if (!currentCard || !currentSelection) {
    return <div>Kaart niet gevonden</div>;
  }

  return (
    <div className="min-h-screen bg-white py-8">
      <div className="container mx-auto px-4">
        <div className="max-w-7xl mx-auto">
          <header className="mb-8">
            <h1 className="text-4xl font-bold text-summa-red mb-2">
              Kies Hulpbronnen
            </h1>
            <p className="text-gray-600">
              Voor: {currentCard.text}
            </p>
          </header>

          <div className="grid gap-4 mb-24">
            {helpSources.map((source) => (
              <button
                key={source.id}
                onClick={() => handleHelpSourceToggle(source.id)}
                className={clsx(
                  'p-4 rounded-lg text-left transition-all',
                  'border-2 hover:border-summa-red',
                  selectedHelpSources.includes(source.id)
                    ? 'border-summa-red bg-summa-red/5'
                    : 'border-gray-200'
                )}
              >
                <p className="font-medium mb-1">{source.title}</p>
                <p className="text-sm text-gray-600">{source.description}</p>
              </button>
            ))}
          </div>

          <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-4">
            <div className="max-w-7xl mx-auto flex items-center justify-between">
              <button
                onClick={() => navigate(`/solutions/${cardId}`)}
                className="text-gray-600 hover:text-gray-900"
              >
                Terug naar oplossingen
              </button>
              <button
                onClick={handleSave}
                disabled={selectedHelpSources.length === 0}
                className={clsx(
                  'px-6 py-3 rounded-lg font-medium transition-all',
                  'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-summa-red',
                  selectedHelpSources.length === 0
                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    : 'bg-summa-red text-white hover:bg-summa-red-dark shadow-md hover:shadow-lg'
                )}
              >
                Opslaan en terug
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};