import React from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { Card } from '../components/Card';
import { cards } from '../data/cards';
import { useStore } from '../store/useStore';

export const SelectedCardsPage: React.FC = () => {
  const navigate = useNavigate();
  const { selectedCards, cardSelections } = useStore();
  const selectedCardsData = cards.filter(card => selectedCards.includes(card.id));

  const allCardsCompleted = selectedCardsData.every(card => 
    cardSelections.some(selection => 
      selection.cardId === card.id && 
      selection.solutions.length > 0 && 
      selection.helpSources.length > 0
    )
  );

  return (
    <div className="min-h-screen bg-white py-8">
      <div className="container mx-auto px-4">
        <div className="max-w-7xl mx-auto">
          <header className="mb-8 flex items-center justify-between">
            <div>
              <h1 className="text-4xl font-bold text-summa-red mb-2">
                Geselecteerde Kaarten
              </h1>
              <p className="text-gray-600">
                Klik op een kaart om oplossingen te kiezen
              </p>
            </div>
            <div className="text-summa-red font-bold text-2xl">
              SUMMA
            </div>
          </header>

          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mb-24">
            {selectedCardsData.map((card) => (
              <div 
                key={card.id}
                onClick={() => navigate(`/solutions/${card.id}`)}
                className="cursor-pointer"
              >
                <Card
                  text={card.text}
                  selected={true}
                />
              </div>
            ))}
          </div>

          <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-4">
            <div className="max-w-7xl mx-auto flex items-center justify-between">
              <button
                onClick={() => navigate('/')}
                className="text-gray-600 hover:text-gray-900"
              >
                Terug naar kaarten
              </button>
              {allCardsCompleted && (
                <button
                  onClick={() => navigate('/export')}
                  className={clsx(
                    'px-6 py-3 rounded-lg font-medium transition-all',
                    'bg-summa-red text-white hover:bg-summa-red-dark',
                    'shadow-md hover:shadow-lg'
                  )}
                >
                  Exporteer Rapport
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};