import { create } from 'zustand';
import { CardSelection, UserInfo } from '../types';

interface StoreState {
  selectedCards: number[];
  cardSelections: CardSelection[];
  userInfo: UserInfo;
  addSelectedCard: (cardId: number) => void;
  removeSelectedCard: (cardId: number) => void;
  addCardSelection: (selection: CardSelection) => void;
  updateCardSelection: (selection: CardSelection) => void;
  setUserInfo: (info: UserInfo) => void;
  resetStore: () => void;
}

export const useStore = create<StoreState>((set) => ({
  selectedCards: [],
  cardSelections: [],
  userInfo: { name: '' },
  
  addSelectedCard: (cardId) =>
    set((state) => ({
      selectedCards: [...state.selectedCards, cardId]
    })),
    
  removeSelectedCard: (cardId) =>
    set((state) => ({
      selectedCards: state.selectedCards.filter(id => id !== cardId)
    })),
    
  addCardSelection: (selection) =>
    set((state) => ({
      cardSelections: [...state.cardSelections, selection]
    })),
    
  updateCardSelection: (selection) =>
    set((state) => ({
      cardSelections: state.cardSelections.map(s =>
        s.cardId === selection.cardId ? selection : s
      )
    })),

  setUserInfo: (info) =>
    set(() => ({
      userInfo: info
    })),
    
  resetStore: () =>
    set({
      selectedCards: [],
      cardSelections: [],
      userInfo: { name: '' }
    })
}));