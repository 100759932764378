export const solutions = [
  { 
    id: 1, 
    text: "Maak een planning voor je huiswerk en verdeel grote taken in kleinere stappen" 
  },
  { 
    id: 2, 
    text: "Vraag je mentor om hulp bij het maken van een studieplanning" 
  },
  { 
    id: 3, 
    text: "Gebruik de pomodoro-techniek: 25 minuten werken, 5 minuten pauze" 
  },
  { 
    id: 4, 
    text: "Maak samenvattingen van de lesstof in je eigen woorden" 
  },
  { 
    id: 5, 
    text: "Vorm een studiegroep met klasgenoten om samen te oefenen" 
  },
  { 
    id: 6, 
    text: "Gebruik online oefeningen en video's om de stof beter te begrijpen" 
  },
  { 
    id: 7, 
    text: "Maak een lijst van vragen die je hebt en bespreek deze met je docent" 
  },
  { 
    id: 8, 
    text: "Zoek een rustige studieplek waar je je goed kunt concentreren" 
  }
];