import { FC } from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import { cards } from '../data/cards';
import { solutions } from '../data/solutions';
import { helpSources } from '../data/helpSources';
import type { CardSelection } from '../types';

const styles = StyleSheet.create({
  page: {
    padding: 30,
    backgroundColor: '#ffffff'
  },
  title: {
    fontSize: 24,
    marginBottom: 20
  },
  subtitle: {
    fontSize: 18,
    marginBottom: 15
  },
  text: {
    fontSize: 12,
    marginBottom: 10
  },
  section: {
    marginBottom: 20
  },
  cardSection: {
    marginBottom: 30
  },
  date: {
    fontSize: 10,
    marginBottom: 20,
    color: '#666666'
  }
});

interface PDFDocumentProps {
  cardSelections: CardSelection[];
  userName: string;
}

export const PDFDocument: FC<PDFDocumentProps> = ({ cardSelections, userName }) => {
  const currentDate = format(new Date(), 'dd MMMM yyyy', { locale: nl });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>Summa Schoolspel Rapport</Text>
          <Text style={styles.text}>Naam: {userName}</Text>
          <Text style={styles.date}>Datum: {currentDate}</Text>
        </View>

        {cardSelections.map((selection) => {
          const card = cards.find(c => c.id === selection.cardId);
          const selectedSolutions = solutions.filter(s => selection.solutions.includes(s.id));
          const selectedHelpSources = helpSources.filter(h => selection.helpSources.includes(h.id));

          return (
            <View key={selection.cardId} style={styles.cardSection}>
              <Text style={styles.subtitle}>Kaart: {card?.text}</Text>
              
              <Text style={styles.text}>Gekozen oplossingen:</Text>
              {selectedSolutions.map(solution => (
                <Text key={solution.id} style={styles.text}>• {solution.text}</Text>
              ))}

              <Text style={styles.text}>Gekozen hulpbronnen:</Text>
              {selectedHelpSources.map(source => (
                <Text key={source.id} style={styles.text}>
                  • {source.title}: {source.description}
                </Text>
              ))}
            </View>
          );
        })}
      </Page>
    </Document>
  );
};