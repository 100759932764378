import { FC } from 'react';
import clsx from 'clsx';

export interface CardProps {
  text: string;
  selected?: boolean;
  onClick?: () => void;
}

export const Card: FC<CardProps> = ({ text, selected, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={clsx(
        'relative aspect-square p-6 rounded-2xl transition-all duration-200',
        'flex flex-col items-center justify-center text-center cursor-pointer',
        selected 
          ? 'bg-summa-red text-white ring-4 ring-summa-red/50' 
          : 'bg-summa-red text-white hover:bg-summa-red-dark'
      )}
    >
      <div className="w-12 h-12 mb-4 bg-white rounded-full flex items-center justify-center">
        <span className="text-summa-red font-bold">MM</span>
      </div>
      <p className="text-sm font-medium mb-2">Summa Schoolspel</p>
      <p className="text-xs">{text}</p>
    </div>
  );
};