import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';
import clsx from 'clsx';
import { PDFDocument } from '../components/PDFDocument';
import { useStore } from '../store/useStore';

export const ExportPage: React.FC = () => {
  const navigate = useNavigate();
  const { cardSelections, userInfo, setUserInfo } = useStore();
  const [name, setName] = useState(userInfo.name);
  const [showNameError, setShowNameError] = useState(false);

  const handleNameSubmit = () => {
    if (name.trim()) {
      setUserInfo({ name: name.trim() });
      setShowNameError(false);
    } else {
      setShowNameError(true);
    }
  };

  return (
    <div className="min-h-screen bg-white py-8">
      <div className="container mx-auto px-4">
        <div className="max-w-7xl mx-auto">
          <header className="mb-8">
            <h1 className="text-4xl font-bold text-summa-red mb-2">
              Exporteer Rapport
            </h1>
            <p className="text-gray-600">
              Download je persoonlijke rapport met alle geselecteerde kaarten, oplossingen en hulpbronnen
            </p>
          </header>

          <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
            <h2 className="text-xl font-semibold mb-4">Vul je naam in</h2>
            <div className="mb-6">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                Je naam
              </label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className={clsx(
                  'w-full px-4 py-2 rounded-lg border',
                  'focus:ring-2 focus:ring-summa-red focus:border-summa-red',
                  showNameError ? 'border-red-500' : 'border-gray-300'
                )}
                placeholder="Vul je naam in"
              />
              {showNameError && (
                <p className="mt-1 text-sm text-red-500">
                  Vul alsjeblieft je naam in voordat je het rapport downloadt
                </p>
              )}
            </div>

            <h2 className="text-xl font-semibold mb-4">Rapport Overzicht</h2>
            <p className="text-gray-600 mb-2">
              Je rapport bevat:
            </p>
            <ul className="list-disc list-inside text-gray-600 mb-6">
              <li>{cardSelections.length} geselecteerde kaarten</li>
              <li>Persoonlijke oplossingen per kaart</li>
              <li>Gekozen hulpbronnen per kaart</li>
            </ul>

            <div className="flex gap-4">
              {userInfo.name ? (
                <PDFDownloadLink
                  document={<PDFDocument cardSelections={cardSelections} userName={userInfo.name} />}
                  fileName="summa-schoolspel-rapport.pdf"
                  className={clsx(
                    'px-6 py-3 rounded-lg font-medium transition-all',
                    'bg-summa-red text-white hover:bg-summa-red-dark',
                    'shadow-md hover:shadow-lg'
                  )}
                >
                  Download PDF Rapport
                </PDFDownloadLink>
              ) : (
                <button
                  onClick={handleNameSubmit}
                  className={clsx(
                    'px-6 py-3 rounded-lg font-medium transition-all',
                    'bg-summa-red text-white hover:bg-summa-red-dark',
                    'shadow-md hover:shadow-lg'
                  )}
                >
                  Ga verder
                </button>
              )}

              <button
                onClick={() => navigate('/selected-cards')}
                className="px-6 py-3 rounded-lg font-medium text-gray-600 hover:text-gray-900"
              >
                Terug naar overzicht
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};