import React from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { Card } from '../components/Card';
import { cards } from '../data/cards';
import { useStore } from '../store/useStore';

export const CardSelectionPage: React.FC = () => {
  const navigate = useNavigate();
  const { selectedCards, addSelectedCard, removeSelectedCard } = useStore();

  const handleCardClick = (cardId: number) => {
    if (selectedCards.includes(cardId)) {
      removeSelectedCard(cardId);
    } else {
      addSelectedCard(cardId);
    }
  };

  const handleContinue = () => {
    if (selectedCards.length > 0) {
      navigate('/selected-cards');
    }
  };

  return (
    <div className="min-h-screen bg-white py-8">
      <div className="container mx-auto px-4">
        <div className="max-w-7xl mx-auto">
          <header className="mb-8 flex items-center justify-between">
            <div>
              <h1 className="text-4xl font-bold text-summa-red mb-2">
                Schoolspel
              </h1>
              <p className="text-gray-600">
                Kies de kaarten die het beste bij jouw situatie passen
              </p>
            </div>
            <div className="text-summa-red font-bold text-2xl">
              SUMMA
            </div>
          </header>

          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mb-24">
            {cards.map((card) => (
              <Card
                key={card.id}
                text={card.text}
                selected={selectedCards.includes(card.id)}
                onClick={() => handleCardClick(card.id)}
              />
            ))}
          </div>

          <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-4">
            <div className="max-w-7xl mx-auto flex items-center justify-between">
              <div className="text-gray-600">
                {selectedCards.length} kaarten geselecteerd
              </div>
              <button
                onClick={handleContinue}
                disabled={selectedCards.length === 0}
                className={clsx(
                  'px-6 py-3 rounded-lg font-medium transition-all',
                  'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-summa-red',
                  selectedCards.length === 0
                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    : 'bg-summa-red text-white hover:bg-summa-red-dark shadow-md hover:shadow-lg'
                )}
              >
                Ga verder
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};